import InitializationProvider from '@c/InitializationProvider';
import NotificationProvider from '@c/notification/NotificationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { initializeStatistics } from 'api/statistics.service';
import { RouteGuard } from 'guards/routeGuard';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import { wrapper } from 'store/store';
import { theme } from 'theme/theme';
import { useFirebase } from '../shared/firebase/useFirebase';
import '../styles/globals.css';
import '../styles/helpers.scss';
import '../styles/sexmap.css';
import '../styles/theme.css';
import '../styles/typography.css';
import { Montserrat } from 'next/font/google';

const monteserrat = Montserrat({
  subsets: ['cyrillic', 'latin'],
  weight: ['300', '400', '500', '600', '700', '800', '900'],
});

if (typeof window !== 'undefined') {
  initializeStatistics();
}
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  useFirebase();
  return (
    <div className={monteserrat.className}>
      <Head>
        <meta
          name="google-site-verification"
          content="jT1C0vIwX-Vyt4IlsMurCNUQr2xy-7iIf6ANa-I31vw"
        />
        <meta name="yandex-verification" content="daa374d189d824c3" />
        <link rel="sexprosvet icon" href="/favicon.ico" />
        <title>Секспросвет | Проститутки и Индивидуалки</title>
        <meta
          name="description"
          content="Секспросвет | Проститутки и Индивидуалки | Проверенные, реальные девушки с отзывами и настоящими фотографиями. Снять проститутку"
        />
        <meta
          property="og:title"
          content="Секспросвет | Проститутки и Индивидуалки"
        />
        <meta
          property="og:description"
          content="Секспросвет | Проститутки и Индивидуалки | Проверенные, реальные девушки с отзывами и настоящими фотографиями. Снять проститутку"
        />
        <meta property="og:url" content="https://sexprosvet.nl/" />
        <meta property="og:type" content="website" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      
                ym(92727159, "init", {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true
                });
              `,
          }}
        />
        <noscript>
          <div>
            <img
              src="https://mc.yandex.ru/watch/92727159"
              style={{ position: 'absolute', left: '-9999px' }}
              alt=""
            />
          </div>
        </noscript>
      </Head>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <QueryClientProvider client={queryClient}>
            <InitializationProvider>
              <RouteGuard>
                <Component {...pageProps} />
              </RouteGuard>
            </InitializationProvider>
          </QueryClientProvider>
        </NotificationProvider>
      </ThemeProvider>
    </div>
  );
}

export default wrapper.withRedux(MyApp);
